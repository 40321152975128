<template>
    <div
        class="todo"
        v-loading="loading"
    >
        <div class="box">
            <router-view
                :userInfo="userInfo"
                :task="task"
                @enrollEndEvent="enrollEndEvent"
                @showInvite="showInvite"
                @taskDetail="taskDetail"
                @sendMessage="sendMessage"
                @reload="loadDetailData"
                @contractStep="contractStep"
                @showContract="showContract"
                @contractSign="contractSign"
                @showSignContract="showSignContract"
                @geekDetail="geekDetail"
                @employerDetail="employerDetail"
            ></router-view>
        </div>

        <!--邀请信息-->
        <el-drawer
            custom-class="list-draw"
            :before-close="closeDraw"
            :visible.sync="inviteDraw"
            type="primary"
            title="邀请信息"
            size="570px"
        >
            <div
                class="invited_detail"
                :style="{padding:'0 30px',height: (screenHeight-150)+'px'}"
                id="chatContainer"
                v-if="task_trade"
            >
                <el-form>
                    <el-form-item
                        label="雇主"
                        label-width="40%"
                        v-if="task_trade && task_trade.employer_name"
                    >{{task_trade.employer_name}}({{task_trade.employer_nick}})</el-form-item>
                    <el-form-item
                        label="成果交付时间"
                        label-width="40%"
                        v-if="task_trade && task_trade.delivery_time"
                    >{{task_trade.delivery_time}}</el-form-item>
                    <el-form-item
                        label="项目开始时间"
                        label-width="40%"
                        v-if="task_trade && task_trade.work_start_time"
                    >{{task_trade.work_start_time}}</el-form-item>
                    <el-form-item
                        label="项目结束时间"
                        label-width="40%"
                        v-if="task_trade && task_trade.work_end_time"
                    >{{task_trade.work_end_time}}</el-form-item>

                    <el-form-item
                        label="项目酬金"
                        label-width="40%"
                        v-if="task.task_type!=3"
                    >{{task_trade.remuneration}} 元</el-form-item>

                    <el-form-item
                        label="项目酬金"
                        label-width="40%"
                        v-if="task.task_type==3"
                    >{{task_trade.remuneration}} 元</el-form-item>

                    <el-form-item
                        label="总工期"
                        label-width="40%"
                        v-if="task.task_type==3"
                    >{{task_trade.work_time}} 天</el-form-item>

                    <el-form-item
                        label="平均每日酬金"
                        label-width="40%"
                        v-if="task.task_type==3"
                    >{{(task_trade.remuneration/task_trade.work_time).toFixed(2)}} 元/天</el-form-item>

                    <!-- <el-form-item label="电子合同"
                                  label-width="40%">
                        <el-switch :value="task_trade.is_contract==1?true:false"
                                   disabled
                                   :active-text="task_trade.is_contract==1?'签署':'不签署'"></el-switch>
                    </el-form-item>-->

                    <el-form-item
                        label="雇主是否托管酬金"
                        label-width="40%"
                    >
                        <el-switch
                            :value="task_trade.is_remuneration==1?true:false"
                            disabled
                            :active-text="task_trade.is_remuneration==1?'同意托管':'不托管（线下交易）'"
                        ></el-switch>
                    </el-form-item>

                    <el-form-item
                        label="极客履约保证金"
                        label-width="40%"
                    >
                        <el-switch
                            :value="task_trade.is_bond==1?true:false"
                            disabled
                            :active-text="task_trade.is_bond==1?'需要托管':'不需要托管'"
                        ></el-switch>
                    </el-form-item>

                    <el-form-item
                        label="履约保证金金额"
                        label-width="40%"
                        v-if="task_trade.is_bond==1"
                    >{{task_trade.bond}} 元</el-form-item>

                    <el-form-item
                        label="雇主支付酬金方式"
                        label-width="40%"
                    >
                        <el-switch
                            :value="task_trade.is_block==1?true:false"
                            disabled
                            :active-text="task_trade.is_block==1?'分段支付':'不分段，一次性全额支付'"
                        ></el-switch>
                    </el-form-item>

                    <el-form-item
                        label="一次性支付费用"
                        v-if="task && task_trade && task_trade.is_block!=1 && task_trade.block_employer.length > 1"
                        label-width="40%"
                    >{{task_trade.block_employer[0]['remuneration']}}</el-form-item>

                    <el-form-item>
                        <el-table
                            :data="task_trade.block_employer"
                            style="width: 100%"
                            v-if="task_trade.is_block==1 && task_trade.block_employer && task_trade.block_employer.length > 1"
                        >
                            <el-table-column
                                prop="title"
                                label="分段名称"
                            ></el-table-column>
                            <el-table-column
                                prop="remuneration"
                                label="分段酬金"
                            ></el-table-column>
                            <el-table-column
                                prop="done_time"
                                style="width:200px;"
                                label="预估分段完成时间"
                            ></el-table-column>
                        </el-table>
                    </el-form-item>

                    <el-form-item
                        label="技术服务费"
                        label-width="40%"
                        v-if="task.trade.system_price > 0 && userInfo.info.user_type==1"
                    >{{task.trade.system_price}} 元</el-form-item>

                    <el-form-item
                        label="工程极客实得酬金"
                        label-width="40%"
                        v-if="userInfo.info.user_type==1"
                    >{{task_trade.geek_price}} 元</el-form-item>
                </el-form>
            </div>
            <div style="position: fixed;bottom:0px;width: 570px;">
                <el-form>
                    <el-form-item
                        v-if="task && task_trade && task_trade.status==0 && userInfo.info.user_type==1"
                        style="margin-bottom: 10px;"
                    >
                        <div class="task_btns">
                            <el-button
                                type="primary"
                                @click="dealInvite(1)"
                                class="btn"
                                style="width:124px;border:none;"
                            >接受邀请</el-button>
                            <el-button
                                class="btn"
                                @click="dealInvite(2)"
                                style="width:124px;margin-left:20px;border:none;"
                            >不接受邀请</el-button>
                        </div>
                    </el-form-item>

                    <!--接受邀请-->
                    <el-form-item
                        v-if="task && task_trade && task_trade.status>=2"
                        style="margin-bottom: 10px;"
                    >
                        <div class="task_btns">
                            <el-button
                                class="btn"
                                disabled
                                style="background:#75b900;color:#fff;border:none;width:124px;"
                            >已接受邀请</el-button>
                        </div>
                    </el-form-item>

                    <!--拒绝邀请-->
                    <el-form-item
                        v-if="task && task_trade && task_trade.status==-1"
                        style="margin-bottom: 10px;"
                    >
                        <div class="task_btns">
                            <el-button
                                class="btn"
                                disabled
                                style="background:#ce0026;color:#fff;border:none;width:124px;"
                            >已拒绝邀请</el-button>
                        </div>
                    </el-form-item>

                </el-form>
            </div>
        </el-drawer>

        <!--签署电子合同 1--->
        <el-drawer
            custom-class="list-draw"
            :before-close="closeDraw"
            :visible.sync="contractStep1"
            type="primary"
            title
            size="700px"
        >
            <div class="contract_step1">
                <h1 class="title">签署电子合同</h1>
                <p class="description">询龙网为雇主及工程极客提供签署电子合同的服务，签署电子合同能够明确双方在项目执行中的权力与义务，促进双方守约执行，确保双方在项目的执行及调解中有法可依，有章可循。</p>
                <div class="step_box">
                    <div
                        class="box"
                        v-if="contractbox1==true"
                    >
                        <div class="icon">
                            <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img4.png'" />
                        </div>
                        <ul class="content">
                            <li>1、若您需要签署电子合同，请在发送邀请时，选择托管酬金。</li>
                            <li>2、当双方成功托管酬金及履约保证金（若需要）后，您可以点击以下按钮，签署电子合同。</li>
                        </ul>

                        <div
                            class="tip"
                            v-if="task.status>=7&& task.trade && task.trade.status>=3"
                        >雇主和工程极客已成功托管资金，您现在可以签署电子合同</div>

                        <div
                            class="tip"
                            v-else
                        >您尚未成功托管酬金或履约保证金</div>

                        <div class="btns">
                            <el-button
                                class="btn"
                                v-if="task.status>=7&& task.trade && task.trade.status>=3"
                                @click="contractStart"
                                style="width:150px;"
                            >开始签署电子合同</el-button>
                            <el-button
                                class="btn"
                                disabled
                                v-else
                                style="background:gray;width:150px;"
                            >开始签署电子合同</el-button>
                        </div>
                    </div>

                    <div
                        class="box"
                        v-if="contractbox2==true"
                    >
                        <div class="icon">
                            <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img4.png'" />
                        </div>
                        <div class="content">
                            <p>您已经开启了签署电子合同的流程，请按以下三个步骤签署，仅当双方都完成签署后，该电子合同才正式生效。</p>
                            <!-- <p>双方已完成电子合同签署！</p> -->
                        </div>

                        <!--极客-->
                        <div
                            class="btns"
                            v-if="userInfo.info.user_type==1"
                            style="margin-top:20px;"
                        >
                            <el-button
                                type="mini"
                                v-if="task.contract && task.contract.geek_update_time<=0 && task.contract.employer_update_time<=0 && !task.contract.geek_address"
                                @click="contractUpdate"
                                style="width:130px"
                            >1.填写合同签署信息</el-button>
                            <el-button
                                type="mini"
                                v-if="task.contract && task.contract.geek_update_time<=0 && task.contract.employer_update_time<=0 && task.contract.geek_address"
                                @click="contractUpdate"
                                style="width:130px"
                            >1.修改合同签署信息</el-button>
                            <el-button
                                type="mini"
                                v-if="task.contract && (task.contract.employer_update_time>0 || task.contract.geek_update_time > 0)"
                                disabled
                                class="gray"
                                style="width:130px"
                            >1.填写合同签署信息</el-button>

                            <el-button
                                type="mini"
                                v-if="task.contract && task.contract.geek_address && task.contract.status < 2"
                                @click="showContract"
                                style="width:130px"
                            >2.预览并确认合同</el-button>
                            <el-button
                                type="mini"
                                v-else
                                disabled
                                class="gray"
                                style="width:130px"
                            >2.预览并确认合同</el-button>

                            <el-button
                                type="mini"
                                @click="contractSign"
                                v-if="task.contract.geek_update_time>0 && task.contract.employer_update_time>0 && task.contract.status < 3"
                                style="width:130px"
                            >3.签署合同</el-button>
                            <el-button
                                type="mini"
                                v-else
                                disabled
                                class="gray"
                                style="width:130px"
                            >3.签署合同</el-button>
                        </div>
                        <!--雇主-->
                        <div
                            class="btns"
                            v-if="userInfo.info.user_type==2"
                            style="margin-top:20px;"
                        >
                            <el-button
                                type="mini"
                                v-if="task.contract && task.contract.employer_update_time<=0 && task.contract.geek_update_time<=0 && !task.contract.employer_address"
                                @click="contractUpdate"
                                style="width:130px"
                            >1.填写合同签署信息</el-button>
                            <el-button
                                type="mini"
                                v-if="task.contract && task.contract.employer_update_time<=0 && task.contract.geek_update_time<=0 && task.contract.employer_address"
                                @click="contractUpdate"
                                style="width:130px"
                            >1.修改合同签署信息</el-button>
                            <el-button
                                type="mini"
                                v-if="task.contract && (task.contract.employer_update_time>0 || task.contract.geek_update_time > 0)"
                                disabled
                                class="gray"
                                style="width:130px"
                            >1.填写合同签署信息</el-button>

                            <el-button
                                type="mini"
                                v-if="task.contract && task.contract.employer_address && task.contract.status < 2"
                                @click="showContract"
                                style="width:130px"
                            >2.预览并确认合同</el-button>
                            <el-button
                                type="mini"
                                v-else
                                disabled
                                class="gray"
                                style="width:130px"
                            >2.预览并确认合同</el-button>

                            <el-button
                                type="mini"
                                @click="contractSign"
                                v-if="task.contract.geek_update_time>0 && task.contract.employer_update_time>0 && task.contract.status < 3"
                                style="width:130px"
                            >3.签署合同</el-button>
                            <el-button
                                type="mini"
                                v-else
                                disabled
                                class="gray"
                                style="width:130px"
                            >3.签署合同</el-button>
                        </div>

                        <div class="res">
                            <div class="title">双方签署情况</div>
                            <div class="user">
                                <span class="employer">雇主</span>
                                <span class="geek">工程极客</span>
                            </div>
                            <div class="item">
                                <span class="tit">合同确认：</span>
                                <span
                                    class="txt sure"
                                    v-if="task.trade && task.contract.employer_update_time>0"
                                >已确认</span>
                                <span
                                    class="txt"
                                    v-else
                                >未确认</span>
                                <span
                                    class="txt sure"
                                    v-if="task.trade && task.contract.geek_update_time>0"
                                >已确认</span>
                                <span
                                    class="txt"
                                    v-else
                                >未确认</span>
                            </div>
                            <div class="item">
                                <span class="tit">合同签署：</span>
                                <span
                                    class="txt sure"
                                    v-if="task.trade && task.trade.employer_contract_time>0"
                                >已签署</span>
                                <span
                                    class="txt"
                                    v-else
                                >未签署</span>
                                <span
                                    class="txt sure"
                                    v-if="task.trade && task.trade.geek_contract_time>0"
                                >已签署</span>
                                <span
                                    class="txt"
                                    v-else
                                >未签署</span>
                            </div>

                            <div
                                style="position:absolute;right:50px;top:85px;"
                                v-if="task.contract.employer_sign_time>0 && task.contract.geek_sign_time>0"
                            >
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="showSignContract"
                                >查看合同</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="e_sign_text">
                    <div class="title">电子合同受司法认可</div>
                    <ul class="e_content">
                        <li>1、《中华人民共和国电子签名法》第十三、十四条：认可可靠电子签名的合法效力</li>
                        <li>2、《中华人民共和国合同法》第十、十一条：认可以数据电文作为合同书书面形式的合法载体</li>
                        <li>3、《中华人民共和国民事诉讼法》第六十三条：认可电子数据作为法定证据种类的合法地位</li>
                    </ul>
                    <div class="e">
                        <div class="icon">
                            <img :src="websiteConfigs.sourceUrl+'/upload/image/e_logo.png'" />
                        </div>
                        <div class="text">
                            <p>询龙网电子合同由杭州天谷信息科技有限公司E签宝提供技术支持</p>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>

        <!--电子合同-->
        <el-drawer
            custom-class="list-draw"
            :before-close="closeDraw"
            :visible.sync="contractDraw"
            type="primary"
            size="1300px"
            class="contract_drawer"
        >
            <div class="contract_form">
                <div class="title">您可以下载合同到本地查看或打印：</div>
                <div class="content">
                    <el-button
                        type="primary"
                        class="download_contract"
                        size="mini"
                        @click="downloadContractFile"
                        style="width:100px"
                    >下载合同</el-button>
                </div>

                <div class="desc">只有在双方均对合同进行确认后，才可以进行签署，如果对方是单位级客户，请检查对方的授权委托书是否上传并准确填写盖章。</div>

                <div class="contract_submit">
                    <div class="left">
                        <div class="tit">雇主确认合同</div>
                        <div class="btn_con">
                            <template v-if="userInfo.info.user_type==2">
                                <span
                                    class="qr"
                                    v-if="task.contract && task.contract.employer_update_time > 0"
                                >已确认合同</span>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    v-else-if="task.contract && task.contract.employer_address && task.contract.status < 2"
                                    @click="innerDrawer = true"
                                    style="width:100px"
                                >确认合同</el-button>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    v-else
                                    @click="showMessage()"
                                    style="width:100px"
                                >确认合同</el-button>
                            </template>
                            <template v-else>
                                <span
                                    class="qr"
                                    v-if="task.contract && task.contract.employer_update_time>0"
                                >已确认合同</span>
                                <span
                                    class="wqr"
                                    v-else
                                >未确认合同</span>
                            </template>
                        </div>
                    </div>

                    <div class="right">
                        <div class="tit">工程极客确认合同</div>
                        <div class="btn_con">
                            <template v-if="userInfo.info.user_type==1">
                                <span
                                    class="qr"
                                    v-if="task.contract && task.contract.geek_update_time>0"
                                    style="width:100px"
                                >已确认合同</span>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    v-else-if="task.contract && task.contract.employer_address && task.contract.status < 2"
                                    @click="innerDrawer = true"
                                    style="width:100px"
                                >确认合同</el-button>
                                <el-button
                                    class="primary"
                                    size="mini"
                                    v-else
                                    @click="showMessage()"
                                    style="width:100px"
                                >确认合同</el-button>
                            </template>
                            <template v-else>
                                <span
                                    class="qr"
                                    v-if="task.contract && task.contract.geek_update_time>0"
                                >已确认合同</span>
                                <span
                                    class="wqr"
                                    v-else
                                >未确认合同</span>
                            </template>
                        </div>
                    </div>
                </div>

                <div
                    class="contract_detail"
                    v-if="task.contract"
                >
                    <!-----个人/工作室雇主------>
                    <template v-if="task.employer.attestation_type==1">
                        <div class="title">雇主签署信息（个人）：</div>
                        <div class="list employer_one">
                            <table>
                                <tbody>
                                    <tr class="item">
                                        <td class="key">委托方：</td>
                                        <td class="values">{{task.contract.employer_name}}</td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">身份证号码：</td>
                                        <td class="values">{{task.contract.employer_idcard?task.contract.employer_idcard:'尚未提交'}}</td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">手机号码：</td>
                                        <td class="values">{{task.contract.employer_mobile?task.contract.employer_mobile:'尚未提交'}}</td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">地址：</td>
                                        <td class="values">{{task.contract.employer_address?task.contract.employer_address:'尚未提交'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>

                    <!-----单位雇主------>
                    <template v-if="task.employer.attestation_type==2">
                        <div class="title">雇主签署信息（单位）：</div>
                        <div class="list">
                            <table>
                                <tbody>
                                    <tr class="item">
                                        <td class="key">委托方：</td>
                                        <td class="values">{{task.contract.employer_name}}</td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">单位法定代表人：</td>
                                        <td class="values">{{task.contract.employer?task.contract.employer:'尚未提交'}}</td>
                                    </tr>

                                    <tr
                                        class="item"
                                        v-if="task.contract.employer_is_proxy!=1"
                                    >
                                        <td class="key">法人身份证号码：</td>
                                        <td class="values">{{task.contract.employer_idcard?task.contract.employer_idcard:'尚未提交'}}</td>
                                    </tr>

                                    <tr
                                        class="item"
                                        v-if="task.contract.employer_is_proxy!=1"
                                    >
                                        <td class="key">法人手机号码：</td>
                                        <td class="values">{{task.contract.employer_mobile?task.contract.employer_mobile:'尚未提交'}}</td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">单位地址：</td>
                                        <td class="values">{{task.contract.employer_address?task.contract.employer_address:'尚未提交'}}</td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">单位联系电话：</td>
                                        <td class="values">{{task.contract.employer_org_phone?task.contract.employer_org_phone:'尚未提交'}}</td>
                                    </tr>

                                    <tr
                                        class="item"
                                        v-if="task.contract.employer_is_proxy==1"
                                    >
                                        <td class="key">受托签署人姓名：</td>
                                        <td class="values">{{task.contract.employer_proxy_name?task.contract.employer_proxy_name:'尚未提交'}}</td>
                                    </tr>

                                    <tr
                                        class="item"
                                        v-if="task.contract.employer_is_proxy==1"
                                    >
                                        <td class="key">受托签署人身份证号码：</td>
                                        <td class="values">{{task.contract.employer_proxy_idcard?task.contract.employer_proxy_idcard:'尚未提交'}}</td>
                                    </tr>

                                    <tr
                                        class="item"
                                        v-if="task.contract.employer_is_proxy==1"
                                    >
                                        <td class="key">受托签署人手机号码：</td>
                                        <td class="values">{{task.contract.employer_proxy_mobile?task.contract.employer_proxy_mobile:'尚未提交'}}</td>
                                    </tr>

                                    <tr
                                        class="item"
                                        v-if="task.contract.employer_is_proxy==1"
                                    >
                                        <td class="key">授权委托书：</td>
                                        <td class="values">
                                            <button @click="checkZs">点击查看</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="remark">
                            <div class="title">雇主向工程极客提供的技术资料及数据的具体约定：</div>
                            <div
                                class="value"
                                v-text="task.contract.remark?task.contract.remark:'尚未提交'"
                            ></div>
                        </div>
                    </template>

                    <!-----个人工程极客------>
                    <template v-if="task.trade.geek.attestation_type==1">
                        <div class="title">工程极客签署信息（个人）：</div>
                        <div class="list employer_one">
                            <table>
                                <tbody>
                                    <tr class="item">
                                        <td class="key">服务方：</td>
                                        <td class="values">{{task.contract.geek_name}}</td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">身份证号码：</td>
                                        <td class="values">{{task.contract.geek_idcard?task.contract.geek_idcard:'尚未提交'}}</td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">手机号码：</td>
                                        <td class="values">{{task.contract.geek_mobile?task.contract.geek_mobile:'尚未提交'}}</td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">地址：</td>
                                        <td class="values">{{task.contract.geek_address?task.contract.geek_address:'尚未提交'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>

                    <!-----工作室工程极客------>
                    <template v-if="task.trade.geek.attestation_type==2">
                        <div class="title">工程极客签署信息（工作室）：</div>
                        <div class="list employer_one">
                            <table>
                                <tbody>
                                    <tr class="item">
                                        <td class="key">服务方：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek"
                                        ></td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">身份证号码：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek_idcard?task.contract.geek_idcard:'尚未提交'"
                                        ></td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">手机号码：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek_mobile?task.contract.geek_mobile:'尚未提交'"
                                        ></td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">地址：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek_address?task.contract.geek_address:'尚未提交'"
                                        ></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>

                    <!-----单位工程极客------>
                    <template v-if="task.trade.geek.attestation_type==3">
                        <div class="title">工程极客签署信息（单位）：</div>
                        <div class="list">
                            <table>
                                <tbody>
                                    <tr class="item">
                                        <td class="key">服务方：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek_name"
                                        ></td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">单位法定代表人：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek?task.contract.geek:'尚未提交'"
                                        ></td>
                                    </tr>

                                    <tr
                                        class="item"
                                        v-if="task.contract.geek_is_proxy!=1"
                                    >
                                        <td class="key">法人身份证号码：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek_idcard?task.contract.geek_idcard:'尚未提交'"
                                        ></td>
                                    </tr>

                                    <tr
                                        class="item"
                                        v-if="task.contract.geek_is_proxy!=1"
                                    >
                                        <td class="key">法人手机号码：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek_mobile?task.contract.geek_mobile:'尚未提交'"
                                        ></td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">单位地址：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek_address?task.contract.geek_address:'尚未提交'"
                                        ></td>
                                    </tr>

                                    <tr class="item">
                                        <td class="key">单位联系电话：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek_org_phone?task.contract.geek_org_phone:'尚未提交'"
                                        ></td>
                                    </tr>

                                    <tr
                                        class="item"
                                        v-if="task.contract.geek_is_proxy==1"
                                    >
                                        <td class="key">受托签署人姓名：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek_proxy_name?task.contract.geek_proxy_name:'尚未提交'"
                                        ></td>
                                    </tr>

                                    <tr
                                        class="item"
                                        v-if="task.contract.geek_is_proxy==1"
                                    >
                                        <td class="key">受托签署人身份证号码：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek_proxy_idcard?task.contract.geek_proxy_idcard:'尚未提交'"
                                        ></td>
                                    </tr>

                                    <tr
                                        class="item"
                                        v-if="task.contract.geek_is_proxy==1"
                                    >
                                        <td class="key">受托签署人手机号码：</td>
                                        <td
                                            class="values"
                                            v-text="task.contract.geek_proxy_mobile?task.contract.geek_proxy_mobile:'尚未提交'"
                                        ></td>
                                    </tr>

                                    <tr
                                        class="item"
                                        v-if="task.contract.geek_is_proxy==1"
                                    >
                                        <td class="key">授权委托书：</td>
                                        <td class="values">
                                            <button @click="checkZs">点击查看</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </div>
            </div>

            <div class="contract_file">
                <div
                    class="pdf_box"
                    style="padding:0;"
                >
                    <iframe
                        class="contract_iframe"
                        :src="pdfUrl"
                    ></iframe>
                </div>
            </div>

            <el-drawer
                title
                :append-to-body="true"
                :with-header="false"
                :before-close="handleClose"
                size="1080px"
                :visible.sync="innerDrawer"
            >
                <div
                    class="contract-child"
                    v-if="task.trade"
                >
                    <div
                        class="contract-head"
                        :style="{backgroundImage:'url('+$img.contract_head+')'}"
                    >
                        <img :src="$img.edit" />
                        <span>确认签署合同信息</span>
                    </div>

                    <div class="contract-body">
                        <el-row>
                            <el-col :span="12">
                                <el-card
                                    class="box-card"
                                    style="margin-left: 60px;margin-right: 20px;"
                                >
                                    <div class="box-card-title">雇主所提交合同签署信息</div>
                                    <div
                                        class="box-card-body"
                                        v-if="task.employer.attestation_type==2"
                                    >
                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >委托方：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer_name}}</el-col>
                                        </el-row>

                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >单位法定代表人：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer?task.contract.employer:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row v-if="task.contract.employer_is_proxy!=1">
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >法人身份证号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer_idcard?task.contract.employer_idcard:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row v-if="task.contract.employer_is_proxy!=1">
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >法人手机号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer_mobile?task.contract.employer_mobile:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >单位地址：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer_address?task.contract.employer_address:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >单位联系电话：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer_org_phone?task.contract.employer_org_phone:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row v-if="task.contract.employer_is_proxy==1">
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >受托签署人姓名：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer_proxy_name?task.contract.employer_proxy_name:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row v-if="task.contract.employer_is_proxy==1">
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >受托签署人身份证号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer_proxy_idcard?task.contract.employer_proxy_idcard:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row v-if="task.contract.employer_is_proxy==1">
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >受托签署人手机号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer_proxy_mobile?task.contract.employer_proxy_mobile:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row
                                            v-if="task.contract.employer_is_proxy==1"
                                            style="text-align: center;padding-top: 40px;padding-bottom: 24px;"
                                        >
                                            <el-button
                                                round
                                                @click="checkZs"
                                                style="border: 1px solid #DCDFE6;"
                                            >查看授权委托书</el-button>
                                        </el-row>
                                    </div>
                                    <div
                                        class="box-card-body"
                                        v-if="task.employer.attestation_type==1"
                                    >
                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >委托方：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer_name}}</el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >身份证号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer_idcard?task.contract.employer_idcard:'尚未提交'}}</el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >手机号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer_mobile?task.contract.employer_mobile:'尚未提交'}}</el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >地址：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.employer_address?task.contract.employer_address:'尚未提交'}}</el-col>
                                        </el-row>
                                    </div>
                                </el-card>
                            </el-col>
                            <el-col :span="12">
                                <el-card
                                    class="box-card"
                                    style="margin-left: 20px;margin-right: 60px;"
                                >
                                    <div class="box-card-title">极客所提交合同签署信息</div>
                                    <div
                                        class="box-card-body"
                                        v-if="task.trade.geek.attestation_type==1"
                                    >
                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >服务方：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_name}}</el-col>
                                        </el-row>

                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >身份证号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_idcard?task.contract.geek_idcard:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >手机号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_mobile?task.contract.geek_mobile:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >地址：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_address?task.contract.geek_address:'尚未提交'}}</el-col>
                                        </el-row>
                                    </div>
                                    <div
                                        class="box-card-body"
                                        v-if="task.trade.geek.attestation_type==2"
                                    >
                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >服务方：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek}}</el-col>
                                        </el-row>

                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >身份证号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_idcard?task.contract.geek_idcard:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >手机号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_mobile?task.contract.geek_mobile:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >地址：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_address?task.contract.geek_address:'尚未提交'}}</el-col>
                                        </el-row>
                                    </div>
                                    <div
                                        class="box-card-body"
                                        v-if="task.trade.geek.attestation_type==3"
                                    >
                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >服务方：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_name}}</el-col>
                                        </el-row>

                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >单位法定代表人：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek?task.contract.geek:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row v-if="task.contract.geek_is_proxy!=1">
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >法人身份证号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_idcard?task.contract.geek_idcard:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row v-if="task.contract.geek_is_proxy!=1">
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >法人手机号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_mobile?task.contract.geek_mobile:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >单位地址：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_address?task.contract.geek_address:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row>
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >单位联系电话：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_org_phone?task.contract.geek_org_phone:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row v-if="task.contract.geek_is_proxy==1">
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >受托签署人姓名：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_proxy_name?task.contract.geek_proxy_name:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row v-if="task.contract.geek_is_proxy==1">
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >受托签署人身份证号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_proxy_idcard?task.contract.geek_proxy_idcard:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row v-if="task.contract.geek_is_proxy==1">
                                            <el-col
                                                :span="9"
                                                style="text-align: right;"
                                                class="box-card-body-col"
                                            >受托签署人手机号码：</el-col>
                                            <el-col
                                                :span="15"
                                                class="box-card-body-col"
                                            >{{task.contract.geek_proxy_mobile?task.contract.geek_proxy_mobile:'尚未提交'}}</el-col>
                                        </el-row>

                                        <el-row
                                            v-if="task.contract.geek_is_proxy==1"
                                            style="text-align: center;padding-top: 40px;padding-bottom: 24px;"
                                        >
                                            <el-button
                                                round
                                                style="border: 1px solid #DCDFE6;"
                                                @click="checkZs"
                                            >查看授权委托书</el-button>
                                        </el-row>
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="contract-form">
                        <div
                            class="contract-form-title"
                            style="color: #587ddc;"
                        >雇主向工程极客提供的技术资料及数据的具体约定</div>
                        <el-input
                            type="textarea"
                            style="padding-top: 10px;"
                            :rows="4"
                            :value="task.contract.remark?task.contract.remark:'尚未提交'"
                            placeholder="请输入内容"
                            disabled
                        ></el-input>
                        <p style="padding-top: 10px;color: #587ddc;">
                            <i class="el-icon-warning"></i>请仔细查看您和对方所提交的合同签署信息。一经确认，双方无法更改，且所填写的合同签署信息将会体现在电子合同中
                        </p>
                    </div>
                    <div class="contract-bottm">
                        <el-row>
                            <el-col
                                :span="12"
                                style="padding-right: 20px;text-align: right;"
                            >
                                <el-button
                                    style="border: 1px solid #DCDFE6;width:100px"
                                    @click="handleClose"
                                >返&nbsp;&nbsp;回</el-button>
                            </el-col>
                            <el-col
                                :span="12"
                                style="padding-left: 20px;"
                            >
                                <el-button
                                    type="primary"
                                    @click="sureContactClick"
                                    style="width:100px"
                                >确&nbsp;&nbsp;认</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-drawer>
        </el-drawer>

        <!--合同签署-->
        <el-drawer
            custom-class="list-draw"
            :before-close="closeDraw"
            :visible.sync="contractDrawSign"
            type="primary"
            size="500px"
            class="contract_drawer"
        >
            <div class="contract_sign">
                <div class="title">合同签署</div>
                <div class="type">
                    <span class="key">方式一：</span> 您可以点击手机上收到的“e签宝”给您发送的合同签署链接，进入页面根据提示签署合同。
                </div>
                <div class="type">
                    <span class="key">方式二：</span> 您也可以使用微信或支付宝扫描下面二维码，根据提示进行签署。
                </div>
                <div class="ecode">
                    <img :src="ecode" />
                </div>
            </div>
        </el-drawer>

        <!--查看签署成功的电子合同-->
        <el-drawer
            custom-class="list-draw"
            :before-close="closeDraw"
            :visible.sync="contractViewDraw"
            type="primary"
            size="830px"
            class="contract_drawer"
        >
            <div class="contract_file">
                <div
                    class="pdf_box"
                    style="padding:0;"
                >
                    <iframe
                        class="contract_iframe"
                        :src="pdfUrl"
                    ></iframe>
                </div>
            </div>
        </el-drawer>

        <!--授权书弹出框-->
        <el-dialog
            :visible.sync="auth_img_dialog"
            center
            :append-to-body="true"
            :lock-scroll="false"
            width="30%"
        >
            <Add></Add>
            <a
                :href="auth_img"
                target="_bank"
            >
                <el-image
                    class="auth_img_dialog"
                    :src="auth_img"
                ></el-image>
            </a>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";
import onlyUpload from "@/components/upload/only-upload.vue";
import Counter from "@/components/common/Counter.vue";

export default {
    name: "taskTodo",
    metaInfo() {
        return {
            title: "待办事项 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },

    components: {
        onlyUpload,
        Counter
    },

    computed: {
        ...mapState(["userInfo"])
    },

    data() {
        return {
            task: "", //项目详情
            task_trade: '',//项目邀请信息
            loading: false,
            inviteDraw: false, //邀请详情抽屉
            contractDraw: false, //电子合同抽屉
            contractDrawSign: false, //合同签署抽屉
            ecode: "", //签署合同二维码
            contractSignForm: {}, //电子合同签署表单
            pdfUrl: "", //pdf文件地址
            numPages: 1,
            timer: "", //定期查询合同是否签署完成
            contractViewDraw: false, //查看已签署合同文件
            contractStep1: false, //签署电子合同步骤1
            contractbox1: true,
            contractbox2: false,
            screenHeight: 500,
            auth_img: '',//授权书
            auth_img_dialog: false,//授权书弹出框
            innerDrawer: false,
        };
    },
    methods: {
        showMessage() {
            this.$message.error("对方尚未提交合同签署信息，请等待对方提交签署信息后，再进行确认。");
        },
        handleClose(done) {
            // this.$confirm('您的电子合同信息还未确定哦，确定关闭吗？')
            // .then(_ => {
            this.innerDrawer = false;
            // })
            // .catch(_ => {});
        },
        //加载项目详情数据
        loadDetailData() {
            let _this = this;
            _this.loading = true;
            _this.post(
                "/task/task/detail_todo",
                { tk: _this.$route.query.tk },
                res => {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.task = res.data;
                        if (
                            _this.task.trade &&
                            _this.task.trade.is_contract == 1 &&
                            _this.task.trade.status >= 3
                        ) {
                            _this.contractbox2 = true;
                            _this.contractbox1 = false;
                        }
                    }
                }
            );
        },

        //获取项目邀请信息
        showInvite(trade_sn) {
            let _this = this;
            _this.loading = true;
            _this.post(
                "/task/task/detail_invite",
                { trade_sn: trade_sn },
                res => {
                    _this.inviteDraw = true;
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.task_trade = res.data;
                    }
                }
            );
        },

        //查看极客详情
        employerDetail(uk, isShowDraw = "") {
            //侧边栏是否保持展开
            if (isShowDraw) {
                sessionStorage.setItem("isShowDraw", true);
            }
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            this.$router.push("/firm/employer?view=1&uk=" + uk);
        },

        //查看极客详情
        geekDetail(uk, isShowDraw = "") {
            //侧边栏是否保持展开
            if (isShowDraw) {
                sessionStorage.setItem("isShowDraw", true);
            }
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            this.$router.push("/firm/index?view=1&uk=" + uk);
        },

        //查看项目详情
        taskDetail(tk) {
            //存储当前值
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            );
            this.$router.push("/task/detail?view=1&tk=" + tk);
        },

        //发送消息
        sendMessage(val) {
            //侧边栏是否保持展开
            if (val && val.isShowDraw == "isShowDraw") {
                sessionStorage.setItem("isShowDraw", true);
            }
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            this.$router.push("/user/message/sendmsg?uk=" + val.uk);
        },

        //关闭抽屉
        closeDraw(done) {
            this.inviteDraw = false; // 关闭抽屉
            this.contractDraw = false;
            this.contractDrawSign = false;
            this.contractViewDraw = false;
            this.contractStep1 = false;
            clearInterval(this.timer);
            sessionStorage.removeItem("isShowDraw"); //移除抽屉状态缓存
        },

        //报名结束
        enrollEndEvent(tk) {
            let _this = this;
            _this.post("/task/home/task_cron?tk=" + tk, function (res) {
                window.location.reload();
            });
        },

        //准备签署电子合同
        contractStep() {
            this.contractStep1 = true;
        },

        //预览电子合同
        showContract() {
            this.pdfUrl = this.websiteConfigs.sourceUrl + "/upload/pdf/web/viewer.html?file=" + this.task.contract_file + ".pdf";
            this.contractDraw = true;
        },

        //查看已签署合同文件
        showSignContract() {
            this.pdfUrl = this.websiteConfigs.sourceUrl + "/upload/pdf/web/viewer.html?file=" + this.task.contract_sign_file + ".pdf";
            this.contractViewDraw = true;
        },

        //下载合同文件
        downloadContractFile() {
            window.location.href =
                this.websiteConfigs.sourceUrl + this.task.contract_file + ".zip";
        },

        //查看授权书
        checkZs() {
            // window.location.href = this.websiteConfigs.sourceUrl + this.task.contract.employer_proxy_certificate;
            this.auth_img = this.websiteConfigs.sourceUrl + this.task.contract.employer_proxy_certificate;
            this.auth_img_dialog = true;
        },

        //接受雇主邀请
        dealInvite(type) {
            let _this = this;
            _this.loading = true;
            _this.inviteDraw = false;
            _this.post(
                "/task/task/deal_invite",
                {
                    tk: _this.$route.query.tk,
                    type: type
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.$message.success("操作成功");
                        _this.loadDetailData();
                        return;
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("提交失败");
                        }
                    }
                }
            );
        },

        sureContactClick() {
            if (this.task.contract && this.task.contract.geek_address && this.task.contract.geek_address) {
                this.$confirm('请仔细查看您和对方所提交的合同签署信息。一经确认，双方无法更改，且所填写的合同签署信息将会体现在电子合同中')
                    .then(_ => {
                        this.sureContact();
                    })
                    .catch(_ => { });
            } else {
                this.$message.error("对方尚未提交合同签署信息，请等待对方提交签署信息后，再进行确认");
            }
        },
        //确认合同
        sureContact() {
            let _this = this;
            _this.post(
                "/task/task/sure_contract_ok",
                {
                    tk: _this.$route.query.tk,
                    contract_sn: _this.task.contract.contract_sn
                },
                function (res) {
                    if (res.code == 200) {
                        _this.contractDraw = false;
                        _this.innerDrawer = false;
                        _this.loadDetailData();
                        _this.$message.success("合同确认成功");
                    } else {
                        if (res.msg) {
                            _this.$message.success(res.msg);
                        } else {
                            _this.$message.error("合同确认操作失败");
                        }
                    }
                }
            );
        },

        //合同签署
        contractSign() {
            let _this = this;
            _this.loading = true;
            _this.contractDraw = false;
            _this.post(
                "/task/task/contract_sign",
                { tk: _this.$route.query.tk },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.ecode = res.data;
                        _this.contractDrawSign = true;
                        _this.contractStep1 = false;
                        // _this.timer = setInterval(() => {
                        //     _this.isSign();
                        // }, 20000);
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("合同签署失败，请重试");
                        }
                    }
                }
            );
        },

        //是否签署成功
        isSign() {
            let _this = this;
            _this.post(
                "/task/task/contract_is_sign",
                {
                    tk: _this.$route.query.tk
                },
                function (res) {
                    if (res.code == 200) {
                        _this.contractDrawSign = false;
                        _this.$message.success("恭喜你，签署完成");
                        _this.contractStep1 = false;
                        _this.loadDetailData(); //刷新数据
                        clearInterval(_this.timer);
                    }
                }
            );
        },

        //开启电子合同签署
        contractStart() {
            let _this = this;
            _this.post(
                "/task/task/contract_start",
                {
                    tk: _this.$route.query.tk
                },
                function (res) {
                    if (res.code == 200) {
                        _this.contractbox2 = true;
                        _this.contractbox1 = false;
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("无法开启电子合同签署");
                        }
                    }
                }
            );
        },

        //填写合同签署信息
        contractUpdate() {
            this.$router.push(
                "/user/task/update_contract?tk=" +
                this.$route.query.tk +
                "&uk=" +
                this.userInfo.info.uk
            );
        }
    },

    created() {
        this.loadDetailData();
        if (this.userInfo.info.user_type == 1) {
            this.$router.push("/user/task/geek?tk=" + this.$route.query.tk);
        } else if (this.userInfo.info.user_type == 2) {
            this.$router.push("/user/task/employer?tk=" + this.$route.query.tk);
        }
        this.isShowDraw = sessionStorage.getItem("isShowDraw") ? true : false;
        this.screenHeight = `${document.documentElement.clientHeight}`;
    }
};
</script>

<style lang="less" scoped>
@import "../../../styles/todos2.less";
@import "../../../styles/todo.less";
.invited_detail {
    overflow-y: scroll;
    height: 670px;
    .el-form-item {
        margin-bottom: 0px;
    }
}
.pdf_box .contract_iframe {
    border-radius: 8px;
    border: none;
    border-bottom: 1px solid #ddd;
    overflow: hidden;
    width: 100%;
    height: 740px;
}

.task_btns {
    margin-top: 10px;
    text-align: center;
    .btn {
        border: 1px solid #eee;
    }
}

.auth_img_dialog {
    height: 600px;
    width: auto;
    .el-image__inner {
        height: 550px;
    }
}

.contract-child {
    padding-top: 40px;
    height: calc(100vh);
    overflow: scroll;
    overflow-x: hidden;
}
.contract_form {
    overflow-y: scroll;
    height: 750px;
}

.contract-head {
    background-image: url("~@/assets/img/contract_head.png");
    height: 50px;
    width: 100%;
    text-align: center;
    line-height: 50px;
}

.contract-head img {
    margin: auto;
    width: 22px;
}

.contract-head span {
    margin-left: 8px;
    font-family: "雅黑";
    font-size: large;
    font-weight: inherit;
}

.box-card {
    border: 1px solid #587ddc;
    background-color: #fff;
    color: #333;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-top: 40px;
    font-family: sans-serif;
    border-radius: 14px;
    margin-bottom: 10px;
}

/deep/.box-card .el-card__body {
    padding: 0px !important;
}

.box-card-title {
    background-color: #587ddc;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: larger;
    font-weight: 900;
    color: aliceblue;
    letter-spacing: 3px;
}

.box-card-body-col {
    padding: 5px;
}

.contract-form {
    margin: 40px 60px 0px 60px;
}

.contract-bottm {
    padding: 40px;
}
</style>

